import React from 'react';
import { ChakraProvider, Box, Image, Text, Link, VStack, HStack, IconButton, Button, extendTheme } from '@chakra-ui/react';
import { FaYoutube, FaTiktok, FaInstagram, FaLinkedin } from 'react-icons/fa';
import logo from './assets/mp.jpg';
import scrapetableIcon from './assets/scrapetable.png';
import aiamasteryIcon from './assets/aiamastery.png';
import hrzn from './assets/hrzn.png';

// Custom theme
const theme = extendTheme({
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="center"
        pt={8}
        maxWidth="600px"
        mx="auto"
      >
        <Image
          borderRadius="full"
          boxSize="120px"
          src={logo}
          alt="Mike Powers"
          mx="auto"
          mb={4}
        />
        <Text fontSize="2xl" fontWeight="bold" mb={8}>
          Hi, I'm Mike <span role="img" aria-label="wave">👋</span>
        </Text>
        <VStack mb={8}>
          <Text fontSize="lg" fontWeight="bold">
            Get Free Data <span role="img" aria-label="down arrow">👇</span>
          </Text>
          <Button
            as={Link}
            href="https://scrapetable.com"
            isExternal
            variant="solid"
            bg="#fefbf3"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            color="black"
            leftIcon={<Image src={scrapetableIcon} boxSize="40px" objectFit="contain" />}
            _hover={{ bg: '#e6e2d6', transform: 'scale(1.05)' }}
            size="lg"
            width="265px"
            mb={6} 
          >
            ScrapeTable
          </Button>
          <Text fontSize="lg" fontWeight="bold">
            Leads Beta Submission <span role="img" aria-label="down arrow">👇</span>
          </Text>
          <Button
            as={Link}
            href="https://www.hrznleads.com/"
            isExternal
            variant="solid"
            bg="#EAF0F1"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            leftIcon={<Image src={hrzn} boxSize="40px" objectFit="contain" p={1} />}
            _hover={{ bg: '#f0f0f0', transform: 'scale(1.05)' }}
            size="lg"
            width="265px" 
            mb={6} 
          >
            Leads BETA
          </Button>
          <Text fontSize="lg" fontWeight="bold">
            Get My Resources <span role="img" aria-label="down arrow">👇</span>
          </Text>
          <Button
            as={Link}
            href="https://aiamastery.com"
            isExternal
            variant="solid"
            bg="#040029"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            color="white"
            leftIcon={<Image src={aiamasteryIcon} boxSize="40px" objectFit="contain" p={2} />}
            _hover={{ bg: '#020014', transform: 'scale(1.05)' }}
            size="lg"
            width="265px"
            mb={6}  
          >
            AIA Mastery
          </Button>
        </VStack>
        <HStack spacing={6} mb={8} justify="center">
          <IconButton
            as={Link}
            href="https://youtube.com/@itsmikepowers"
            aria-label="YouTube"
            icon={<FaYoutube />}
            bg="red.500"
            color="white"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            _hover={{ bg: 'red.600', transform: 'scale(1.05)' }}
            size="lg"
            fontSize="3xl"
          />
          <IconButton
            as={Link}
            href="https://tiktok.com/@itsmikepowers"
            aria-label="TikTok"
            icon={<FaTiktok />}
            bg="black"
            color="white"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            _hover={{ bg: 'gray.800', transform: 'scale(1.05)' }}
            size="lg"
            fontSize="3xl"
          />
          <IconButton
            as={Link}
            href="https://instagram.com/itsmikepowers"
            aria-label="Instagram"
            icon={<FaInstagram />}
            bg="purple.500"
            color="white"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            _hover={{ bg: 'purple.600', transform: 'scale(1.05)' }}
            size="lg"
            fontSize="3xl"
          />
          <IconButton
            as={Link}
            href="https://linkedin.com/in/itsmikepowers"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
            bg="blue.500"
            color="white"
            border="3px solid black"
            borderBottomWidth="6px"
            borderRightWidth="6px"
            borderRadius="10px"
            _hover={{ bg: 'blue.600', transform: 'scale(1.05)' }}
            size="lg"
            fontSize="3xl"
          />
        </HStack>
        <Text fontSize="lg">
          <strong>Email: </strong>
          <Link href="mailto:mikepowersofficial@gmail.com">mikepowersofficial@gmail.com</Link>
        </Text>
        <Text fontSize="lg" fontWeight="bold"mt={6}>
          Book A Time To Chat <span role="img" aria-label="down arrow">👇</span>
        </Text>
        <Box mt={8}>
          <iframe
            src="https://calendly.com/horizonlabsai/meet"
            width="100%"
            height="1600px"
            frameBorder="0"
            title="Book a meeting with me"
            style={{ minWidth: '320px', borderRadius: '8px' }}
          ></iframe>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default App;
